import React from 'react'

const index = () => {
    return (
        <>
            <div className="loader_wraper">
                <div className="loader"></div>
            </div>
        </>
    )
}

export default index
