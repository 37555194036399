import React from "react";
import patient from "../../../../assets/images/patient.png";

const Tafaseel = () => {
  return (
    <>
      <div className="main_tafaseel">
        <div className="container">
          <div className="tafaseel_wrapper">
            <div className="tafseeel_heading_wrap">
              <div className="inner_heading">
                <h5>تواريخ الرحلة</h5>
                <p>الوصول 20 يونيو صباحا. المغادرة 26 يونيو ظهراً</p>
                <div className="Liabilities">
                  <h5>نظام الوجبات</h5>
                  <p>
                    النظام النباتي الغير مطهي <br /> بقيادة وإشراف معلم التأملات
                    والتنفسات النورانية<span>عصام رضا</span>
                  </p>
                </div>
                <h4>
                  المقاعد محدودة جداً، ولا يسمح بانضمام الحوامل أو المرضعات
                </h4>
                <div className="information new_year">
                  <h3>قيمة الاستثمار</h3>
                </div>
                <h5>التسجيل المبكّر</h5>
                <p>
                  حتى تاريخ 25 مارس 2024 <span> (حسب توفر الأماكن)</span>
                </p>
                <h4>للشخص
                  <br></br>4480$ للدفع على قسطين
                  <br></br>4950$ للدفع على 4 أقساط
                  <br></br>
                  <br></br>عرض الثنائي في غرفة مزدوجة
                  <br></br>8960 $ للشخصين والدفع على 4 اقساط
                </h4>
                <h5>التسجيل المتأخر</h5>
                <p>
                  من تاريخ 26 مارس - 20 مايو
                  <span> (حسب توفر الأماكن)</span>
                  <br></br>
                  4480$ دفعة واحدة فقط للشخص
                  <br></br>
                  4950$ للدفع على قسطين للشخص
                </p>
              </div>
              <div className="wassol">
                <h5>اللغة</h5>
                <p>العربية</p>
              </div>
            </div>
            <div className="patient_img">
              <img src={patient} alt="patient" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tafaseel;
