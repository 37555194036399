import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";

const columns = [
  {
    name: "Id",
    selector: (row, i) => i + 1,
  },
  {
    name: "Payment ID",
    selector: (row) => row.paymentId,
    width: "400px",
    sortable: true,
  },
  {
    name: "Payment Date",
    selector: (row) => new Date(row.createdAt).toDateString() + ' ' + new Date(row.createdAt).toLocaleTimeString(),
    width: "400px",
    sortable: true,
  },
  {
    name: "Live Mode",
    selector: (row) => row.live_mode,
    width: "150px",
    sortable: true,
  },
  {
    name: "Payment For",
    selector: (row) => row.paymentFor,
    width: "250px",
    sortable: true,
  },
  {
    name: "Type",
    selector: (row) => row.type,
    width: "200px",
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    width: "200px",
    sortable: true,
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    width: "200px",
    sortable: true,
  },
  {
    name: "Currency",
    selector: (row) => row.currency,
    width: "200px",
    sortable: true,
  },
  {
    name: "First Name",
    selector: (row) => row.first_name,
    width: "300px",
    sortable: true,
  },
  {
    name: "Last Name",
    selector: (row) => row.last_name,
    width: "300px",
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    width: "400px",
    sortable: true,
  },
  {
    name: "Phone",
    selector: (row) => row.phone,
    width: "300px",
    sortable: true,
  },
  {
    name: "Packages",
    selector: (row) => row.packages ?? '',
    width: "700px",
    sortable: true,
  },
  {
    name: "Payment Agreement Id",
    selector: (row) => row.payment_agreement_id ?? row.payment_agreement_id,
    width: "550px",
    sortable: true,
  },
];
const headersForCSV = [
  "Id",
  "Payment ID",
  "Payment Date",
  "Live Mode",
  "Payment For",
  "Type",
  "Status",
  "Amount",
  "Currency",
  "First Name",
  "Last Name",
  "Email",
  "Phone",
  "Packages",
  "Payment Agreement Id",
];
const customStyles = {
  headCells: {
    style: {
      fontSize: "30px",
      fontWeight: "bold",
      backgroundColor: "#362F4B",
      color: "white",
      textAlign: "center", // Align text to center
    },
  },
  rows: {
    style: {
      fontSize: "30px", // Adjust font size for body data
    },
  },
  pagination: {
    style: {
      textAlign: "right", // Align pagination to the right
    },
  },
};
const tableStyles = {
  borderRadius: "10px", // Rounded corners
};

export const Payments = () => {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const csvData = [headersForCSV, ...data.map((item, index) =>
    [
      index + 1,
      item.paymentId,
      item.createdAt,
      item.live_mode,
      item.paymentFor,
      item.type,
      item.status,
      item.amount,
      item.currency,
      item.first_name,
      item.last_name,
      item.email,
      item.phone,
      item.packages,
      item.payment_agreement_id ?? '',
    ]
  )];

  useEffect(() => {
    // Prompt for password input
    const enteredPassword = prompt('Enter your password:');

    // Validate the password (replace with your actual validation logic)
    if (enteredPassword === 'InnerLight@123') {
      setIsPasswordCorrect(true);
    } else {
      setIsPasswordCorrect(false);
      alert('Invalid password. Access denied.');
    }
    const fetchData = async () => {
      try {
        const url = new URL(`${process.env.REACT_APP_SERVER_URL}/api/payments`);
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setData(result.data);
        setOriginalData(result.data);
      } catch (error) {
        console.error(`Error fetching data: ${error.message}`);
      }
    };
    fetchData();
  }, []);

  const handleSearch = (value) => {
    if (value.trim() === '') {
      // If the search value is empty, reset the data to the original data
      setData(originalData);
    } else {
      // Filter the data based on the search value
      const filteredData = originalData.filter((item) => {
        return Object.values(item).some(
          (fieldValue) =>
            fieldValue && fieldValue.toString().toLowerCase().includes(value.toLowerCase())
        );
      });
      setData(filteredData);
    }
  };
  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}_${(currentDate.getMonth() + 1).toString().padStart(2, '0')}_${currentDate.getDate().toString().padStart(2, '0')}`;

  return (
    <>
      {isPasswordCorrect ? (
        // Render your component content only if the password is correct
        <div className="thank_u main_bg">
          <div className="container">
            <div className="">
              <div className="main_heading">
                <h1>Payments</h1>
                <div style={tableStyles}> {/* Apply styles to the table container */}
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    paginationPerPage={20}
                    customStyles={customStyles}
                    searchable
                    subHeader
                    subHeaderComponent={
                      // Custom search bar component
                      <>
                        <input
                          type="text"
                          placeholder="Search..."
                          onChange={(e) => handleSearch(e.target.value)}
                          style={{ fontSize: "20px", padding: "5px" }}
                        />
                        <CSVLink data={csvData} className="export" filename={`innerlight_payments_${formattedDate}.csv`}>
                          Export
                        </CSVLink>
                      </>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
