import React from "react";
import DescriptionImage from "../../../assets/images/order-description.png";
import DescriptionContentImg from "../../../assets/images/description_content.png";

export const OrderDescription = () => {
  return (
    <>
      <div className="description_id_main order_info_wrapper">
        <div className="descrption_main">
          <div className="description_img">
            <img src={DescriptionImage} alt="" />
          </div>
          <div className="description_content">
            <h1>معتكف العناصر الخمسة ديتوكس العقل والجسد والنفس والروح</h1>
            <div className="descrip_inner_img">
              <img src={DescriptionContentImg} alt="" />
            </div>
            <p>المقاعد محدودة جداً، ولا يسمح بانضمام الحوامل أو المرضعات</p>
            {/* <div className="description_date">
              <h5>التسجيل المبكّر</h5>
              <p>حتى تاريخ 15 مارس 2024 (حسب توفر الأماكن) </p>
            </div>
            <div className="description_date">
              <h5>التسجيل المتأخر</h5>
              <p>
                من تاريخ 16 مارس - 25 مارس 2024 (حسب توفر الأماكن)
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
