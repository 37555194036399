import React, { useState, useEffect } from "react";
import girl from "../../../../assets/images/formgirl.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const PersonalIformation = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const submitButtonClass = isChecked ? 'info_btn' : 'info_btn_disabled';

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required("الإسم الأول مطلوب")
      .min(3, "قصير جدا")
      .max(30, "طويل جدا"),
    lastName: yup
      .string()
      .required("إسم العائلة مطلوب")
      .min(3, "قصير جدا")
      .max(30, "طويل جدا"),
    email: yup
      .string()
      .required("البريد الالكتروني مطلوب")
      .matches(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        "تنسيق بريد إلكتروني غير صالح"
      ),
    confirmEmail: yup
      .string()
      .oneOf([yup.ref("email"), null], "يجب أن يتطابق البريد الإلكتروني"),
    phone: yup
      .string()
      .required("رقم الهاتف مطلوب")
      .matches(/^[0-9]+$/, "يجب أن يحتوي رقم الهاتف على أرقام فقط")
      .min(11, "يجب ألا يقل رقم الهاتف عن 11 رقمًا")
      .max(16, "يمكن أن يتكون رقم الهاتف من 16 رقمًا على الأكثر"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleFormSubmit = async (data) => {
    const { firstName, lastName, email, phone } = data;
    // Store user information in local storage or context if needed
    const userInfo = { firstName, lastName, email, phone };
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    // Navigate to the checkout page
    navigate("/order");
  };

  const handleDownloadPdf = () => {
    window.open('/تفاصيل معتكف العناصر الخمسة.pdf', '_blank');
  };

  useEffect(() => {
    localStorage.removeItem("userInfo");
  }, []);

  return (
    <>
      <div className="form_wrapper">
        <div className="container">
          <div className="personal_info_wrapper">
            <div className="girl_img">
              <img src={girl} alt="girl" />
            </div>
            <div className="form-inner-wraper">
              <h2>احجز مقعدك الآن</h2>
              <p>
                يرجى تعبئة البيانات التالية وإتمام عملية الدفع لتأكيد التسجيل في
                معتكف العناصر الخمسة - بوكيت{" "}
              </p>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="overall-form">
                  <div className="input-wrapper">
                    <label>الاسم الأول</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("firstName")}
                    />
                    <span className="input-error">
                      {errors.firstName?.message}
                    </span>
                  </div>
                  <div className="input-wrapper">
                    <label className="mt-10">اسم العائلة</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("lastName")}
                    />
                    <span className="input-error">
                      {errors.lastName?.message}
                    </span>
                  </div>
                </div>
                <div className="overall-form">
                  <div className="input-wrapper">
                    <label>البريد الإلكتروني</label>
                    <input
                      type="email"
                      className="form-control"
                      {...register("email")}
                    />
                    <span className="input-error">{errors.email?.message}</span>
                  </div>
                  <div className="input-wrapper">
                    <label className="mt-10">اعد كتابة البريد الالكتروني</label>
                    <input
                      type="email"
                      className="form-control"
                      {...register("confirmEmail")}
                    />
                    <span className="input-error">
                      {errors.confirmEmail?.message}
                    </span>
                  </div>
                </div>
                <div className="overall-form">
                  <div className="input-wrapper w-100">
                    <label>الهاتف مع فتح الخط</label>
                    <input
                      type="text"
                      className="w-100 form-control"
                      {...register("phone")}
                    />
                    <span className="input-error">{errors.phone?.message}</span>
                  </div>
                </div>
                <div className="order_form_check">
                  <input
                    type="checkbox"
                    id="order_form_check"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="order_form_check">
                    ر بأني قد أطلعت على ملف تفاصيل الرحلة
                  </label>
                  <button type="button" className="download_btn" onClick={handleDownloadPdf}>ملف تفاصيل الرحلة</button>
                </div>
                <div className="form_btn move_btn">
                  <button type="submit" className={submitButtonClass} disabled={!isChecked}>
                    إتمام عملية الدفع
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalIformation;
