import React, {useEffect} from 'react';
import CheckoutForm from './CheckoutForm';
import { useNavigate } from 'react-router-dom';

export const OrderForm = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    // Check if userInfo exists in local storage
    const storedUserInfo = localStorage.getItem('userInfo');
    if (!storedUserInfo) {
      // If userInfo doesn't exist, navigate back to the previous page
      navigate('/');
    }
  }, [navigate]);

  return (
    <>
      <div className='order_form_main right_side_pannel'>
        <div className='form_head'>
          <h1>طريقة الدفع او السداد</h1>
          <p>الدفع عن طريق البطاقة</p>
        </div>
        <div className='order_form'>
          <CheckoutForm />
        </div>
      </div>
    </>
  )
}
