import React, { useState } from "react";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Loader from "../Loader/index"

const CheckoutForm = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [paymentOption, setPaymentOption] = useState("4");
  const [loading, setLoading] = useState(false);
  const [paymentFor] = useState("retreat");
  const textStyle = { color: paymentOption === "2" ? "red" : "black" };
  const submitButtonClass = isChecked ? 'order_form_btn' : 'order_form_btn_disabled';
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required("الإسم الأول مطلوب")
      .min(3, "قصير جدا")
      .max(30, "طويل جدا"),
    lastName: yup
      .string()
      .required("إسم العائلة مطلوب")
      .min(3, "قصير جدا")
      .max(30, "طويل جدا"),
    email: yup
      .string()
      .required("البريد الالكتروني مطلوب")
      .matches(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        "تنسيق بريد إلكتروني غير صالح"
      ),
    confirmEmail: yup
      .string()
      .oneOf([yup.ref("email"), null], "Email must match"),
    phone: yup
      .string()
      .required("رقم الهاتف مطلوب")
      .matches(/^[0-9]+$/, "يجب أن يحتوي رقم الهاتف على أرقام فقط")
      .min(11, "يجب ألا يقل رقم الهاتف عن 11 رقمًا")
      .max(16, "يمكن أن يتكون رقم الهاتف من 16 رقمًا على الأكثر"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = async (formData) => {
    try {
      const { firstName, lastName, email, phone } = formData;
      const userInfo2 = { firstName, lastName, email, phone };
      chargeInstallments(userInfo2);
    } catch (error) {
      toast.error("Failed to make payment. Please try again.");
    }
  };

  const handleForm = async (e) => {
    e.preventDefault();
    try {
      const userInfo2 = { firstName: "", lastName: "", email: "", phone: "" };
      chargeInstallments(userInfo2);
    } catch (error) {
      toast.error("Failed to make payment. Please try again.");
    }
  };

  const chargeInstallments = async (userInfo2) => {
    try {
      setLoading(true);
      toast.info("Please wait...");
      const paymentUrl = paymentOption == "4" ? "/api/charge" : "/api/chargeInstallments";
      const res = await fetch(process.env.REACT_APP_SERVER_URL + paymentUrl, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({
          userInfo: JSON.parse(localStorage.getItem("userInfo")),
          paymentOption,
          userInfo2,
          paymentFor
        }),
      });
      const data = await res.json();
      window.location.href = data.data.transaction.url;
    } catch (error) {
      toast.error("Failed to make payment. Please try again.");
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handlePaymentOptionChange = (event) => {
    setPaymentOption(event.target.value);
  };

  return (
    <form onSubmit={paymentOption === "3" ? handleSubmit(handleFormSubmit) : handleForm}>
      <h1>خيارات الدفع لمشترك واحد فقط</h1>
      <br></br>
      <label>
        <input
          type="radio"
          name="radioGroup"
          value="4"
          checked={paymentOption === "4"}
          onChange={handlePaymentOptionChange}
        />
        دفع المبلغ بالكامل 4480 دولار
      </label>
      <br></br>
      <label>
        <input
          type="radio"
          name="radioGroup"
          value="1"
          checked={paymentOption === "1"}
          onChange={handlePaymentOptionChange}
        />
        دفع المبلغ بالأقساط (قسطين) اجمالي المبلغ 4950 دولار، قيمة القسط 2475 دولار
      </label>
      <br></br>
      {/* <label>
        <input
          type="radio"
          name="radioGroup"
          value="2"
          checked={paymentOption === "2"}
          onChange={handlePaymentOptionChange}
        />
        دفع المبلغ بالأقساط (4 أقساط) اجمالي المبلغ 4950 دولار، قيمة القسط 1237.5 دولار
      </label>
      <br></br> */}
      <p>
        القسط الأول غير قابل للاسترداد فور التسجيل، ويتم تحصيل مبلغ القسط التالي بعد شهر من القسط السابق لذا يجب التأكد من توفر المبلغ الكافي للقسط لتجنب إلغاء حجزك
      </p>
      <br></br>
      {/* <h1>العرض الخاص للثنائي (الغرفة المزدوجة)</h1>
      <br></br>
      <label>
        <input
          type="radio"
          name="radioGroup"
          value="3"
          checked={paymentOption === "3"}
          onChange={handlePaymentOptionChange}
        />
        دفع المبلغ بالأقساط (4 أقساط) 4480 دولار للشخص الواحد بإجمالي 8960 دولار للشخصين، قيمة القسط 2240 دولار
      </label>
      <p>
        الرجاء ادخال بيانات المشترك الثاني فور التسجيل، ويتم تحصيل مبلغ القسط التالي بعد شهر من القسط السابق لذا يجب التأكد من توفر المبلغ الكافي للقسط لتجنب إلغاء حجزك
      </p> */}
      {
        paymentOption === "3" ? (
          <>
            <div className="overall-form justify-none">
              <div className="input-wrapper ml-20">
                <label>الاسم الأول</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("firstName")}
                />
                <span className="input-error">
                  {errors.firstName?.message}
                </span>
              </div>
              <div className="input-wrapper">
                <label className="mt-10">اسم العائلة</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("lastName")}
                />
                <span className="input-error">
                  {errors.lastName?.message}
                </span>
              </div>
            </div>
            <div className="overall-form justify-none">
              <div className="input-wrapper ml-20">
                <label>البريد الإلكتروني</label>
                <input
                  type="email"
                  className="form-control"
                  {...register("email")}
                />
                <span className="input-error">{errors.email?.message}</span>
              </div>
              <div className="input-wrapper">
                <label className="mt-10">اعد كتابة البريد الالكتروني</label>
                <input
                  type="email"
                  className="form-control"
                  {...register("confirmEmail")}
                />
                <span className="input-error">
                  {errors.confirmEmail?.message}
                </span>
              </div>
            </div>
            <div className="overall-form">
              <div className="input-wrapper w-100">
                <label>الهاتف مع فتح الخط</label>
                <input
                  type="text"
                  className="w-100 form-control"
                  {...register("phone")}
                />
                <span className="input-error">{errors.phone?.message}</span>
              </div>
            </div>
          </>
        ) : null
      }
      <div className="order_form_check">
        <input
          type="checkbox"
          id="order_form_check"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="order_form_check">
          قرأت وأوافق على الشروط والأحكام
        </label>
      </div>
      <div className={submitButtonClass}>
        {loading ? (
          <Loader />
        ) : (
          <button type="submit" disabled={!isChecked}>
            أكمل طلبي
          </button>
        )}
      </div>
    </form>
  );
};

export default CheckoutForm;
