import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Loader from "../Loader/index"

const CheckoutForm = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [paymentOption, setPaymentOption] = useState("1");
  const [userInfo, setUserInfo] = useState({});
  const [fullPrice, setFullPrice] = useState(0);
  const [installmentPrice, setInstallmentPrice] = useState(0);
  const [paymentFor] = useState("ramadan");
  const [loading, setLoading] = useState(false);
  const submitButtonClass = isChecked ? 'order_form_btn' : 'order_form_btn_disabled';

  const handleForm = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      toast.info("Please wait...");
      const paymentUrl = paymentOption === "1" ? "/api/charge" : "/api/chargeInstallments";
      const price = paymentOption === "1" ? fullPrice : installmentPrice;
      const res = await fetch(process.env.REACT_APP_SERVER_URL + paymentUrl, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({
          userInfo,
          paymentOption,
          price,
          paymentFor
        }),
      });

      const data = await res.json();
      window.location.href = data.data.transaction.url;
    } catch (error) {
      toast.error("Failed to make payment. Please try again.");
    }
  };

  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem("ramadanUserInfo")));
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.checkboxes) {
      const checkboxValues = userInfo.checkboxes;
      if (
        checkboxValues.includes("tick-one") &&
        checkboxValues.includes("tick-two") &&
        checkboxValues.includes("tick-three")
      ) {
        setFullPrice(221);
        setInstallmentPrice(252);
      } else if (
        checkboxValues.includes("tick-one") &&
        checkboxValues.includes("tick-two")
      ) {
        setFullPrice(189);
        setInstallmentPrice(220);
      } else if (
        checkboxValues.includes("tick-two") &&
        checkboxValues.includes("tick-three")
      ) {
        setFullPrice(126);
        setInstallmentPrice(160);
      } else if (
        checkboxValues.includes("tick-one") &&
        checkboxValues.includes("tick-three")
      ) {
        setFullPrice(189);
        setInstallmentPrice(220);
      } else if (checkboxValues.includes("tick-one")) {
        setFullPrice(126);
        setInstallmentPrice(140);
      } else if (checkboxValues.includes("tick-two")) {
        setFullPrice(63);
        setInstallmentPrice(80);
      } else if (checkboxValues.includes("tick-three")) {
        setFullPrice(63);
        setInstallmentPrice(80);
      } else {
        setFullPrice(221);
      }
    }
  }, [userInfo]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handlePaymentOptionChange = (event) => {
    setPaymentOption(event.target.value);
  };

  return (
    <form onSubmit={handleForm}>
      <label>
        <input
          type="radio"
          name="radioGroup"
          value="1"
          checked={paymentOption === "1"}
          onChange={handlePaymentOptionChange}
        />
        المبلغ الإجمالي هو {fullPrice} ريال عماني
      </label>
      <br />
      <label>
        <input
          type="radio"
          name="radioGroup"
          value="2"
          checked={paymentOption === "2"}
          onChange={handlePaymentOptionChange}
        />
        دفع المبلغ بالأقساط (4 أقساط): {installmentPrice} ريال عماني بواقع {installmentPrice / 4} ريال
      </label>
      <div className="order_form_check">
        <input
          type="checkbox"
          id="order_form_check"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="order_form_check">
          قرأت وأوافق على الشروط والأحكام
        </label>
      </div>
      <div className={submitButtonClass}>
        {loading ? (
          <Loader />
        ) : (
          <button type="submit" disabled={!isChecked}>
            أكمل طلبي
          </button>
        )}
      </div>
    </form>
  );
};

export default CheckoutForm;
