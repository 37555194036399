import React, { useEffect } from "react";
import { OrderDescription } from "./OrderDescription";
import { OrderForm } from "./OrderForm";

export const OrderPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="main_bg">
        <div className="order_wrapper">
          <OrderDescription />
          <OrderForm />
        </div>
      </div>
    </>
  );
};
