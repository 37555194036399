import React, { useEffect, useState } from 'react'
import Header from "./Header/Header";
import { Outlet } from "react-router-dom";
import Loader from "../../common/Loader"
const Layout = () => {
	const [loader, setLoader] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoader(false);
		}, 2000);
	}, []);

	return (
		<>
			{loader ? (
				<Loader />
			) : ""}
			<div className="TopMainSection order_wrapper_page">
				<Header />
				<Outlet />
			</div>
		</>
	);
};

export default Layout;
