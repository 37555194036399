import React, { useEffect } from 'react'
import sitelogo from '../../../../assets/images/site-logo.png';

const Header = () => {

    return (
        <>
            <div className='main_wrapper'>
                <div className='container'>
                    <header>
                        <div className='site_logo'>
                            <img src={sitelogo} alt="sitelogo" />
                        </div>
                    </header>
                </div>
            </div>
        </>
    )
}

export default Header
