import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const ThankYou = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const paymentFor = searchParams.get('paymentFor');

  useEffect(() => {
    toast.success('Payment successful!');
    localStorage.removeItem('userInfo');
    setTimeout(() => {
      navigate(paymentFor === "ramadan" ? "/ramadan" : "/");
    }, 5000);
  }, [navigate, paymentFor]);

  return (
    <>
      <div className='thank_u main_bg'>
        <div className='container'>
          <div className=''>
            <div className='main_heading'>
              <h1>شكرًا لك على طلبك</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
