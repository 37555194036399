import React from 'react'
import Banner from './Banner/Banner';
import Tafaseel from './Tafaseel/Tafaseel';
import PersonalInformation from './PersonalInformation/PersonalInformation';

export const HomePage = () => {

  return (
    <>
      <div className='main_bg'>
        <Banner />
        <Tafaseel />
        <PersonalInformation />
      </div>
    </>
  )
}
