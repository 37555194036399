import "./App.css";
import "../src/assets/css/responsive.css"
import { Route, Routes } from "react-router-dom";
import Layout from "./components/static/Layout/Layout";
import { OrderPage } from "./components/common/OrderPage";
import { HomePage } from "./components/common/HomePage";
import { ThankYou } from "./components/common/ThankYou";
import { Checkout } from "./components/common/Checkout";
import { Payments } from "./components/common/Payments";
import { RamadanRetreat } from "./components/common/RamadanRetreat";
import { RamadanOrderPage } from "./components/common/RamadanOrderPage";
import ManualPayment from "./components/common/ManualPayment/ManualPayment";
import Loader from "./components/common/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/order" element={<OrderPage />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/ramadan" element={<RamadanRetreat />} />
          <Route path="/ramadan-order" element={<RamadanOrderPage />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/manual-payment" element={<ManualPayment />} />
          <Route path="/Loader" element={<Loader />} />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
};
export default App;
