import React from 'react'

const Banner = () => {
  return (
    <>
      <div className='container'>
        <div className=''>
          <div className='main_heading'>
             <h1>معتكف العناصر الخمسة<br/> ديتوكس العقل والجسد والنفس والروح</h1>
          </div>
          <div className='sub_heading'>
            <h2>مع معلم التأملات والتنفسات النورانية <span>عصام رضا</span></h2>
          </div>
          <div className='information'>
            <h3>الهدف من الرحلة</h3>
            <p>اعرف طبائعك من خلال معرفة العناصر وهي الطبيعة التي منها خُلِقنا وبها نعيش وفيها نحيا وتعلم كيفية توازنها من خلال مجموعة تطبيقات وممارسات من ضمنها التنفسات والتأملات والتمارين. <br/> الإنسان عبارة عن مزيح من هذه الخيميائية بكل تناغمها وتناقضها. وعدم توازن هذه العناصر بالقدر المناسب للفرد وببصمته الفريدة قد يؤدي إلى اشكاليات في الصحة والحيوية والعلاقات وبقية المجالات.</p>
          </div>
          <div className='information'>
            <h3>مكان الرحلة</h3>
            <p>مركز التعافي ذي لايف اند كو المصنف ضمن أفضل 10 مراكز للتعافي والصحة والقاطن في جزيرة بوكيت الساحرة في تايلاند <br/> هذا المعتكف فريد من نوعه ويتيح لك فرصة عمل ديتوكس على كافة المستويات، عقلياً، جسدياً، نفسياً روحياً، إضافة إلى المعلومات والمحاضرات واليوغا والأنشطة المتنوعة والمقدمة من قبل الخبراء في هذا المجال.</p>
          </div>
          <div className='information new_year'>
            <h3>تفاصيل الرحلة - يونيو <span>2024</span></h3>
          </div>
        </div>
      </div>
    </>
  )
}

export default Banner
