import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

export const Checkout = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const tap_id = queryParameters.get("tap_id");
  const navigate = useNavigate()
  const [checkoutData, setCheckoutData] = useState({});

  const fetchData = async () => {
    try {
      if (tap_id) {
        const url = new URL(process.env.REACT_APP_SERVER_URL + "/api/charge");
        url.searchParams.append("tap_id", tap_id);
        const res = await fetch(url);
        const data = await res.json();
        setCheckoutData(data?.data)

        if (data?.data?.status === "CAPTURED") {
          navigate(`/thank-you?paymentFor=${data?.data?.metadata?.paymentFor}`)
        } else {
          toast.error("Failed to make payment. Please try again");
        }
      } else {
        toast.error("Invalid payment parameters");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("An error occurred. Please try again.");
    }
  }
  useEffect(() => {
    fetchData();
  }, [tap_id]);
  return (
    <>
      <div className="thank_u main_bg">
        <div className="container">
          <div className="">
            <div className="main_heading">
              <h1>يرجى الانتظار</h1>
            </div>
          </div>
        </div>
      </div>
      <Link to={`${checkoutData?.metadata?.paymentFor === "ramadan" ? "/ramadan" : "/"}`}>
        <button type="submit" className="info_btn">
          الصفحة الرئيسية
        </button>
      </Link>
    </>
  );
};
