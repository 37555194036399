import React, { useEffect } from 'react'
import Card from "../../../assets/images/card_1.jpeg"
import Reading from "../../../assets/images/card_3.jpeg"
import Date from "../../../assets/images/card_2.jpeg"
import FormBanner from "../../../assets/images/ramdan-form.jpeg"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const RamadanRetreat = () => {
    const navigate = useNavigate();

    const schema = yup.object().shape({
        firstName: yup
            .string()
            .required("الإسم الأول مطلوب")
            .min(3, "قصير جدا")
            .max(30, "طويل جدا"),
        lastName: yup
            .string()
            .required("إسم العائلة مطلوب")
            .min(3, "قصير جدا")
            .max(30, "طويل جدا"),
        email: yup
            .string()
            .required("البريد الالكتروني مطلوب")
            .matches(
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                "تنسيق بريد إلكتروني غير صالح"
            ),
        confirmEmail: yup
            .string()
            .oneOf([yup.ref("email"), null], "يجب أن يتطابق البريد الإلكتروني"),
        phone: yup
            .string()
            .required("رقم الهاتف مطلوب")
            .matches(/^[0-9]+$/, "يجب أن يحتوي رقم الهاتف على أرقام فقط")
            .min(11, "يجب ألا يقل رقم الهاتف عن 11 رقمًا")
            .max(16, "يمكن أن يتكون رقم الهاتف من 16 رقمًا على الأكثر"),
        checkboxes: yup.array().min(1, 'يجب تحديد على الأقل خيار واحد').required('يجب تحديد على الأقل خيار واحد'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const handleFormSubmit = async (data) => {
        const userInfo = data;
        localStorage.setItem("ramadanUserInfo", JSON.stringify(userInfo));
        navigate("/ramadan-order");
    };

    useEffect(() => {
        localStorage.removeItem("ramadanUserInfo");
    }, []);

    return (
        <>
            <div className="ramdan_bg main_bg p-0">
                <div className='ramdan-banner'></div>
                <div className='divine-light'>
                    <div className='ramdan_content'>
                        <div className='main_heading ramdan_mt'>
                            <h1>سلام ورحمة ونور إلهي مقدس</h1>
                        </div>
                        <div className='sub_heading'>
                            <h2>كل الشهور مجراها من الجسم إلى الروح ، الإهتمام بالمظهر والهندام والمعاملات والطعام</h2>
                        </div>
                        <div className='ramdan_para'>
                            <p>إلا شهر رمضان مساره من الروح إلى الجسم ، فيقل الغذاء الجسدي ويزداد الغذاء الروحي ، تقل التعاملات مع الآخرين إلا للضرورة ويتعمق التعامل مع النفس ، ويتحول الإهتمام من الخارج إلى الداخل ، من الجسد إلا الروح ، ومن نقطة الروح هذه نبدء السير تمهيداً لخلق جسم جديد وبطاقة متجددة وبداية جديدة و واقع جديد <span>بإذن الله</span></p>
                            <p>والبدايات الجديدة بحاجة إلى نور إلهي مقدس ، بحاجة إلى تغذية سماوية نقية أكثر من تغذية أرضية عشوائية ، ولهذه النية نقدم برامج مميزة في كل رمضان ، برامج تشمل تعاليم وتأملات وتنفسات بنية التطهير والتزكية والتنوير والتقوية الروحانية</p>
                            <p>في الشهر الفضيل نبدء رحلة فريدة من نوعها ، رحلة نحلق فيها في فضاء من فضاءات الروح ، الفضاء الرابع ، دورة (فضاء الصمت) ، حيث للصمت حديث ، وللحديث صمت</p>
                        </div>
                        <div className='providers_heading'>
                            <p>كذلك سنقدم باقة جديدة من التنفسات الشامانورانية (مسارات الجنة)</p>
                            <p>٤ تنفسات للدخول في مسارات جديدة إلى ممالك بديع السموات والأرض بإذن اللّه</p>
                            <p>بالإضافة إلى برنامج الإستغفار والتوبة للتطهير والتزكية ، جلسات أسبوعية وحلقات ذكر وتشافي نوراني بإذن الله</p>
                            <h3>نرحب بك في أسرة النور الداخلي ، نرحب بك في رحلة نور إلى المنتهى</h3>
                        </div>
                    </div>
                </div>
                <div className='ramdan_content_wrapper'>
                    <div className='meal_system_cards'>
                        <div className='card_wrapper'>
                            <div className='card_image'>
                                <img src={Card} alt="card" />
                                <div className="over-lay-heading card-third">
                                    <h3>حلقات ذكر الاستغفار للتطهير والتزكية</h3>
                                </div>
                            </div>
                            <div className='card-inner-info'>
                                <h3>توقيت اللقاءات :</h3>
                                <h2>كل سبت من 3 إلى 5 م بتوقيت مسقط</h2>
                                <div className="card-listing">
                                    <strong>الجلسة الأولى</strong><span>16 مارس</span>
                                </div>
                                <div className="card-listing">
                                    <strong>الجلسة الثانية</strong><span>23 مارس</span>
                                </div>
                                <div className="card-listing">
                                    <strong>الجلسة الثالثة</strong><span>30 مارس</span>
                                </div>
                                <div className="card-listing">
                                    <strong>الجلسة الرابعة</strong><span>06 أبريل</span>
                                </div>
                                <div className="card-listing">
                                    <strong>دفع المبلغ بالكامل:</strong><span>63 ريال عماني</span>
                                </div>
                                <div className="card-listing">
                                    <strong>دفع المبلغ بالأقساط (4 أقساط):</strong><span>80 ريال عماني بواقع 20 ريال</span>
                                </div>
                            </div>
                        </div>
                        <div className='card_wrapper'>
                            <div className='card_image'>
                                <img src={Reading} alt="card" />
                                <div className="over-lay-heading">
                                    <h3>التنفسات الشامانورانية (مسارات الجنة)</h3>
                                </div>
                            </div>
                            <div className='card-inner-info'>
                                <h3>توقيت اللقاءات :</h3>
                                <h2>كل أربعاء من 8:30 - 10:30م بتوقيت مسقط</h2>
                                <div className="card-listing">
                                    <strong>مسار دار السلام</strong><span>13 مارس</span>
                                </div>
                                <div className="card-listing">
                                    <strong>مسار دار القرار</strong><span>20 مارس</span>
                                </div>
                                <div className="card-listing">
                                    <strong>مسار جنات عدن</strong><span>27 مارس</span>
                                </div>
                                <div className="card-listing">
                                    <strong>مسار الفردوس</strong><span>3 أبريل</span>
                                </div>
                                <div className="card-listing">
                                    <strong>دفع المبلغ بالكامل:</strong><span>63 ريال عماني</span>
                                </div>
                                <div className="card-listing">
                                    <strong>دفع المبلغ بالأقساط (4 أقساط):</strong><span>80 ريال عماني بواقع 20 ريال</span>
                                </div>
                            </div>
                        </div>
                        <div className='card_wrapper'>
                            <div className='card_image'>
                                <img src={Date} alt="card" />
                                <div className="over-lay-heading">
                                    <h3>دورة فضاء الصمت</h3>
                                </div>
                            </div>
                            <div className='card-inner-info'>
                                <h3>توقيت اللقاءات :</h3>
                                <h2>كل أثنين من 8:30 إلى 10:30 م بتوقيت مسقط</h2>
                                <div className="card-listing">
                                    <strong>الدرس الأول</strong><span>11 مارس</span>
                                </div>
                                <div className="card-listing">
                                    <strong>الدرس الثاني</strong><span>18 مارس</span>
                                </div>
                                <div className="card-listing">
                                    <strong>الدرس الثالث</strong><span>25 مارس</span>
                                </div>
                                <div className="card-listing">
                                    <strong>الدرس الرابع </strong><span>1 أبريل</span>
                                </div>
                                <div className="card-listing">
                                    <strong>دفع المبلغ بالكامل:</strong><span>126 ريال عماني</span>
                                </div>
                                <div className="card-listing">
                                    <strong>دفع المبلغ بالأقساط (4 أقساط):</strong><span>140 ريال عماني بواقع 35 ريال</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='ramdan_form_wrapper'>
                        <div className="form_wrapper">
                            <div className="container">
                                <div className="personal_info_wrapper">
                                    <div className="girl_img">
                                        <img src={FormBanner} alt="card" />
                                    </div>
                                    <div className="form-inner-wraper">
                                        <h2>احجز مقعدك الآن</h2>
                                        <p>يرجى تعبئة البيانات وإتمام عملية الدفع لحجز مقعدك في برامج شهر رمضان
                                            الفضيل - تواصل معنا عبر رقم الواتس اب في حال حاجتك للمساعدة 96892133633
                                            <p>لا تفوت العرض الخاص عند تسجيلك في جميع البرامج</p>
                                        </p>
                                        <form onSubmit={handleSubmit(handleFormSubmit)}>
                                            <div className="overall-form">
                                                <div className="input-wrapper">
                                                    <label>الاسم الأول</label>
                                                    <input type="text" className="form-control" name="firstName" {...register("firstName")} />
                                                    <span className="input-error">{errors.firstName?.message}</span>
                                                </div>
                                                <div className="input-wrapper">
                                                    <label className="mt-10">اسم العائلة</label>
                                                    <input type="text" className="form-control" name="lastName" {...register("lastName")} />
                                                    <span className="input-error">{errors.lastName?.message}</span>
                                                </div>
                                            </div>
                                            <div className="overall-form">
                                                <div className="input-wrapper">
                                                    <label>البريد الإلكتروني</label>
                                                    <input type="email" className="form-control" name="email" {...register("email")} />
                                                    <span className="input-error">{errors.email?.message}</span>
                                                </div>
                                                <div className="input-wrapper">
                                                    <label className="mt-10">اعد كتابة البريد الالكتروني</label>
                                                    <input type="email" className="form-control" name="confirmEmail" {...register("confirmEmail")} />
                                                    <span className="input-error">{errors.confirmEmail?.message}</span>
                                                </div>
                                            </div>
                                            <div className="overall-form">
                                                <div className="input-wrapper w-100">
                                                    <label>الهاتف مع فتح الخط</label>
                                                    <input type="text" className="w-100 form-control" name="phone" {...register("phone")} />
                                                    <span className="input-error">{errors.phone?.message}</span>
                                                </div>
                                            </div>
                                            <div className='listing-heading'>
                                                <div className='validate-heading'>
                                                    <h3>اختر البرنامج</h3>
                                                </div>
                                            </div>
                                            <p>تحصل على تخفيض إضافي في حال تسجليك بجميع البرامج واتمام دفع المبلغ بالكامل 221 ريال عماني بدلاً من 252 ريال عماني</p>
                                            <div className='listing-wrapper'>
                                                <div className='info-wrap'>
                                                    <div className='tick-image'>
                                                        <div className="retreat_checkbox">
                                                            <div className='input-bg bg-none'>
                                                                <span className='trim-rick'></span>
                                                                <input type="checkbox" id="tick-one" {...register("checkboxes")} value="tick-one" />
                                                                <label htmlFor="tick-one"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='tick-info'>
                                                        <p>دورة فضاء الصمت</p>
                                                    </div>
                                                </div>
                                                <div className='info-wrap'>
                                                    <div className='tick-image'>
                                                        <div className="retreat_checkbox">
                                                            <div className='input-bg bg-none'>
                                                                <span className='trim-rick'></span>
                                                                <input type="checkbox" id="tick-two" {...register("checkboxes")} value="tick-two" />
                                                                <label htmlFor="tick-two"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='tick-info'>
                                                        <p>التنفسات الشامانورانية (مسار الجنة)</p>
                                                    </div>
                                                </div>
                                                <div className='info-wrap'>
                                                    <div className='tick-image'>
                                                        <div className="retreat_checkbox">
                                                            <div className='input-bg bg-none'>
                                                                <span className='trim-rick'></span>
                                                                <input type="checkbox" id="tick-three" {...register("checkboxes")} value="tick-three" />
                                                                <label htmlFor="tick-three"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='tick-info'>
                                                        <p>حلقات ذكر الاستغفار للتطهير والتزكية</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="input-error">
                                                {errors.checkboxes?.type === 'min' ? errors.checkboxes?.message : errors.checkboxes?.type === 'typeError' ? "يجب تحديد على الأقل خيار واحد" : ""}
                                            </span>
                                            <div className="form_btn move_btn">
                                                <button type="submit" className="info_btn">إتمام عملية الدفع</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

