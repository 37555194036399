import React, { useState } from "react";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Loader from "../Loader/index"

const ManualPayment = () => {
    const [paymentLink, setPaymentLink] = useState()
    const [loader, setLoader] = useState(false)
    const schema = yup.object().shape({
        paymentId: yup
            .string()
            .required("مطلوب معرف الدفع")
            .min(3, "قصير جدا")
            .max(30, "طويل جدا"),
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const handleForm = async (formData) => {
        try {
            setLoader(true)
            const paymentUrl = "/api/chargeManualInstallments";
            const res = await fetch(process.env.REACT_APP_SERVER_URL + paymentUrl, {
                headers: { "Content-Type": "application/json" },
                method: "POST",
                body: JSON.stringify({
                    paymentId: formData.paymentId
                }),
            });
            const data = await res.json();
            if (!data.success) {
                toast.error(data?.message);
            } else {
                setPaymentLink(data?.data?.transaction.url);
            }
        } catch (error) {
            toast.error("Failed to make payment. Please try again.");
        }
        finally {
            setLoader(false); // Set loading to false after request completes
        }
    };

    const copytext = (event) => {
        event.preventDefault();
        navigator.clipboard.writeText(paymentLink);
        toast.success("This link has been copied to your clipboard")
    }
    return (
        <>
            <form onSubmit={handleSubmit(handleForm)}>
                <div className="input-wrapper input-wrapperWidth">
                    <label>معرف الدفع</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="chg_LV04I1520242152j5P52503897"
                        {...register("paymentId")}
                    />
                    <span className="input-error">
                        {errors.paymentId?.message}
                    </span>
                    <div className="order_form_btn">
                        <button type="submit" className="form_btn_width" disabled={loader}>
                            {loader ? <Loader /> : "إنشاء رابط الدفع"}
                        </button>
                    </div>
                </div >
            </form>
            {
                paymentLink ? (<form className="custom-space">
                    <div className="input-wrapper input-wrapperWidth custom-width">
                        <label>انت انتهيت </label>
                        <p className="subheading">
                            انسخ رابط الدفع الخاص بك
                        </p>
                        <input
                            type="text"
                            className="form-control"
                            value={paymentLink}
                        />
                        <span className="input-error">
                            {errors.paymentId?.message}
                        </span>
                        <div className="order_form_btn">
                            <button onClick={copytext} className="form_btn_width" >
                                نسخ الوصلة
                            </button>
                        </div>
                    </div >
                </form>
                ) : null
            }
        </>
    );
};

export default ManualPayment;
